/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import withRouter from 'HOCs/withRouter';
import { compose } from 'redux';
import * as flows from './async';
import PropTypes from 'prop-types';
import withConfig from 'HOCs/withConfig/withConfig';
import { legacyListingRoutesObj } from 'Constants/legacyRegexConstants';

const componentMapper = {
    [legacyListingRoutesObj.SLUG_CATEGORY]: flows.importListing,
    [legacyListingRoutesObj.SLUG_CATEGORY_CLASSIFIED]: flows.importListing,
    [legacyListingRoutesObj.SLUG_GEO]: flows.importListing,
    [legacyListingRoutesObj.SLUG_GEO_CLASSIFIED]: flows.importListing,
    '(:lang/)items(/q-:text)': flows.importListing,
    '(:lang/)olxmobbi/items(/q-:text)': flows.importListing,
    [legacyListingRoutesObj.SLUG_CATEGORY_ID]: flows.importListing,
    [legacyListingRoutesObj.SLUG_CATEGORY_ID_CLASSIFIED]: flows.importListing,
    [legacyListingRoutesObj.SLUG_GEO_CAT]: flows.importListing,
    [legacyListingRoutesObj.SLUG_GEO_CAT_CLASSIFIED]: flows.importListing,
    [legacyListingRoutesObj.SLUG_LOCATION_ID]: flows.importHome,
    [legacyListingRoutesObj.SLUG_LOCATION_ID_CLASSIFIED]: flows.importHome
};

const getComponent = (config, { params, location }) => {
    let legacyRouteComponent;

    if ([legacyListingRoutesObj.SLUG_CATEGORY_ID, legacyListingRoutesObj.SLUG_GEO_CAT]
        .includes(params?.matchedLegacyRoute) && location?.pathname?.includes('/all-filters')) {
        legacyRouteComponent = flows.importListingFilters();
    }
    else if ([legacyListingRoutesObj.SLUG_CATEGORY_ID_CLASSIFIED, legacyListingRoutesObj.SLUG_GEO_CAT_CLASSIFIED]
        .includes(params?.matchedLegacyRoute) && location?.pathname?.includes('/all-filters')) {
        legacyRouteComponent = flows.importListingFilters();
    }
    else {
        const component = componentMapper[params.matchedLegacyRoute];

        legacyRouteComponent = (component || flows.importNotFound)(config, { params, location });
    }
    return legacyRouteComponent;
};

const HomePageHOC = props => {
    const { location, params, config } = props;

    const configObj = {
        categoryCover: config.get('categoryCover'),
        olxAutos: config.get('olxAutos')
    };

    const LegacyRouteComponent = useMemo(() => getComponent(configObj, {
        location,
        params
    }), []);

    return <LegacyRouteComponent { ...props } />;
};

HomePageHOC.propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
    matchedLegacyRoute: PropTypes.string,
    config: PropTypes.object
};

const connectedComponent = compose(
    withConfig,
    withRouter
)(HomePageHOC);

connectedComponent.fetchData = (dispatch, renderProps, reqProps, reqContext, store) => {
    return getComponent(reqContext.config, {
        location: renderProps.location,
        params: renderProps.params
    }).load()
        .then(Comp => Comp.fetchData && Comp.fetchData(dispatch, renderProps, reqProps, reqContext, store));
};

export default connectedComponent;
